import React from 'react';
import ReactDOM from 'react-dom';
import DiscountPage from "./discountPage";

let added = false;

const cart = document.querySelector('.t706__carticon')
if (cart) {
    cart.addEventListener('click', addASGKBlockFromButton)
} else {
    const intervalForCart = setInterval(() => {
        const cart = document.querySelector('.t706__carticon')
        if (cart) {
            cart.addEventListener('click', addASGKBlockFromButton)
            clearInterval(intervalForCart);
        }
    }, 500);
}

const cart1 = document.querySelector('.t706__sidebar-bottom')
if (cart1) {
    cart1.addEventListener('click', addASGKBlockFromButton)
} else {
    const intervalForCart = setInterval(() => {
        const cart1 = document.querySelector('.t706__sidebar-bottom')
        if (cart1) {
            cart1.addEventListener('click', addASGKBlockFromButton)
            clearInterval(intervalForCart);
        }
    }, 500);
}

const cart3 = document.querySelector('.t706__cartpage-open-form')
if (cart3) {
    cart3.addEventListener('click', addASGKBlockFromButton)
} else {
    const intervalForCart = setInterval(() => {
        const cart3 = document.querySelector('.t706__cartpage-open-form')
        if (cart3) {
            cart3.addEventListener('click', addASGKBlockFromButton)
            clearInterval(intervalForCart);
        }
    }, 500);
}

const cart4 = document.querySelector('.t396__elem.tn-elem.cartcopy_elem')
if (cart4) {
    cart4.addEventListener('click', addASGKBlockFromButton)
} else {
    const intervalForCart = setInterval(() => {
        const cart4 = document.querySelector('.t396__elem.tn-elem.cartcopy_elem')
        if (cart4) {
            cart4.addEventListener('click', addASGKBlockFromButton)
            clearInterval(intervalForCart);
        }
    }, 500);
}

setTimeout(() => {
    const orderButton = document.querySelectorAll('[href*="#order"]');
    orderButton.forEach(button => {
        button.addEventListener('click', addASGKBlockFromButton);
    })
}, 50);

setTimeout(() => {
    const orderButton = document.querySelectorAll('[href*="tproduct"]');
    orderButton.forEach(button => {
        button.addEventListener('click', () => {
            setTimeout(() => {
                const orderButton = document.querySelectorAll('[href*="#order"]');
                orderButton.forEach(button => {
                    button.addEventListener('click', addASGKBlockFromButton);
                })
            }, 50);
        });
    })
}, 50);

const form: HTMLFormElement | null = document.querySelector('[data-formsended-callback]');
const asgk = document.querySelector('#asgk');
if (form && !added) {
    if (asgk) {
        asgk.remove();
    }
    added = true;
    addASGKBlock(form);
}

function addASGKBlock(form: HTMLFormElement) {
    const newDiv = document.createElement("div");
    newDiv.setAttribute("id", "asgk");
    newDiv.setAttribute('style', "margin: 40px 0px");
    form.getElementsByClassName('t-form__errorbox-middle')[0].append(newDiv)

    ReactDOM.render(
        <>
            {form && <DiscountPage form={form}/>}
        </>,
        document.getElementById('asgk'),
    );
}

function addASGKBlockFromButton() {
    setTimeout(() => {
        const form: HTMLFormElement | null = document.querySelector('[data-formsended-callback]');
        const asgk = document.querySelector('#asgk');
        if (form && !added) {
            if (asgk) {
                asgk.remove();
            }
            added = true;
            addASGKBlock(form);
        }
    }, 50);
}
