import React, {useEffect, useState} from "react";
import AsgkApi, {loyaltyTypeDto, User} from "../models/asgkApi";
import Verify from "./Verify";
import ErrorBlock from "./ErrorBlock";
import SuccessBlock from "./SuccessBlock";

interface BonusCardProps {
    operation?: loyaltyTypeDto;
    user: User;
    correctPaymentSystem: boolean;
    form: HTMLFormElement;
    asgk: AsgkApi;
    setUser: (arg0: User) => void;
    observer?: MutationObserver;
    setObserver: (arg0: MutationObserver) => void;
}

export default function BonusCard(props: BonusCardProps) {

    const [user, setUser] = useState<User>();
    const [operation, setOperation] = useState<loyaltyTypeDto>();
    const [allBonus, setAllBonus] = useState(0);
    const [bonusWillNeedSpend, setBonusWillNeedSpend] = useState(0);
    const [bonusWillSpend, setBonusWillSpend] = useState(0);
    const [allowedBonus, setAllowedBonus] = useState(0);
    const [loyaltyLevel, setLoyaltyLevel] = useState('');
    const [discountPercent, setDiscountPercent] = useState(0);
    const [discountPercentInMoney, setDiscountPercentInMoney] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [finishPrice, setFinishPrice] = useState(0);
    const [sum, setSum] = useState(0);
    const [needVerify, setNeedVerify] = useState(false);
    const [showBonusField, setShowBonusField] = useState(true)
    const [typeSendCode, setTypeSendCode] = useState('')
    const [successText, setSuccessText] = useState('');
    const [errorText, setErrorText] = useState('');
    const [code, setCode] = useState(0);
    const [waitSeconds, setWaitSeconds] = useState(0)

    useEffect(() => {
        if (waitSeconds > 0) {
            const interval = setInterval(() => {
                setWaitSeconds(waitSeconds - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [waitSeconds])

    useEffect(() => {
        changePrice()
    }, [sum, discountPercentInMoney, props.user, props.operation, bonusWillSpend])

    useEffect(() => {
        if (code > 0) {
            bonusOff(code)
        }
    }, [code])

    useEffect(() => {
        if (props.operation) {
            if (operation?.signature !== props.operation?.signature) {
                setOperation(props.operation)
                setShowBonusField(true)
                setBonusWillSpend(0)
                setTypeSendCode('')
                setNeedVerify(false)
                setUser(props.operation.user)
                setBonusWillNeedSpend(0)
            }
            let summ_without_discount = 0
            try {
                summ_without_discount = Number(document
                    .getElementsByClassName('t706__cartwin-bottom')[0]
                    .getElementsByClassName('t706__cartwin-prodamount-price')[0]
                    .textContent?.replace(' ', '').replace(',', '.'))
            } catch (e) {
            }

            if (summ_without_discount === 0) {
                try {
                    summ_without_discount = Number(document
                        .getElementsByClassName('t706__cartwin-totalamount')[0]
                        .getElementsByClassName('t706__cartwin-prodamount-price')[0]
                        .textContent?.replace(' ', '').replace(',', '.'))
                } catch (e) {
                }
            }

            setSum(summ_without_discount)

            if (props.operation.need_verify) {
                setNeedVerify(true)
            }

            setDiscountPercent(Number(props.operation.discount?.value.replace('%', '')))
            setDiscountPercentInMoney(props.operation.discount?.summ ?? 0)
            setAllBonus(Number(props.operation.cashback?.existed_summ))
            setAllowedBonus(Number(props.operation.cashback?.allowed_summ))
            if (props.operation.cashback) {
                setLoyaltyLevel(String(props.operation.cashback?.loyalty_level) ?? '');
            } else if (props.operation.discount) {
                setLoyaltyLevel(String(props.operation.discount?.loyalty_level) ?? '');
            }
        }
    }, [props.operation, user])

    function validateBonus(bonus: number) {
        if (!user) return false

        const bonusNew = Number(String(bonus).replace(/^0+/, ''))
        const discount = Number(String(user.discount).replace('%', ''))
        const newSum = (sum - (sum / 100 * discount))

        if (bonusNew > allowedBonus || bonusNew > sum) {
            if (allowedBonus < sum) {
                setBonusWillNeedSpend(allowedBonus)
            } else {
                setBonusWillNeedSpend(Math.floor(newSum))
            }
        } else if (bonusNew < 0 || isNaN(bonusNew)) {
            setBonusWillNeedSpend(0)
        } else {
            setBonusWillNeedSpend(bonusNew)
        }
    }

    function beBonusWillNeedSpendAndCheckVerify() {
        if (!user) return false
        props.asgk.sendCode(user.user_id, user.operation_id)
            .then(verify => {
                setTypeSendCode(verify.send_by)
                setWaitSeconds(60)
            })
            .catch(e => {
                setShowBonusField(true)
                setErrorTextBlock(e.response.data)
                setTimeout(() => {
                    setErrorTextBlock('')
                }, 5000)
            })
    }

    function bonusOff(code: number) {
        if (!user) return false
        props.asgk.verifyCode(user.user_id, user.operation_id, code, bonusWillNeedSpend)
            .then(verify => {
                setBonusWillSpend(verify.write_off)
                setSuccessTextBlock(verify.message)
                setTimeout(() => {
                    setSuccessTextBlock('')
                }, 5000)
                setTypeSendCode('')
                setShowBonusField(false)
            })
            .catch(e => {
                if (typeSendCode.length === 0) {
                    setBonusWillSpend(0)
                    setShowBonusField(true)
                }
                setErrorTextBlock(e.response.data)
                setTimeout(() => {
                    setErrorTextBlock('')
                }, 5000)
            })
    }

    function setSuccessTextBlock(message: string) {
        setSuccessText(message)
        setTimeout(() => {
            setSuccessText('')
        }, 5000)
    }

    function setErrorTextBlock(message: string) {
        setErrorText(message)
        setTimeout(() => {
            setErrorText('')
        }, 5000)
    }

    function changePrice() {
        let totalSum = 0
        let totalSumDiv

        try {
            totalSumDiv = props.form
                .getElementsByClassName('t706__cartwin-totalamount-wrap')[0]
                .getElementsByClassName('t706__cartwin-totalamount')[0]
                .getElementsByClassName('t706__cartwin-prodamount-price')[0]

            totalSum = Number(totalSumDiv?.textContent?.replace(' ', '').replace(',', '.'))
        } catch (e) {
        }

        if (totalSum === 0) {
            try {
                totalSumDiv = document
                    .getElementsByClassName('t706__cartwin-totalamount-wrap')[0]
                    .getElementsByClassName('t706__cartwin-totalamount')[0]
                    .getElementsByClassName('t706__cartwin-prodamount-price')[0]

                totalSum = Number(totalSumDiv?.textContent?.replace(' ', '').replace(',', '.'))

            } catch (e) {
            }
        }

        const price = totalSum - discountPercentInMoney - bonusWillSpend
        setDiscount(discountPercentInMoney)
        setFinishPrice(price)
    }

    function saveBonusValue() {
        setShowBonusField(false)
        if (needVerify) {
            beBonusWillNeedSpendAndCheckVerify()
        } else {
            bonusOff(0)
        }
    }

    return <>
        {props.correctPaymentSystem && props.operation && user
            ? <div>
                <div className="t-input-title t-descr t-descr_md">Имя: {user?.last_name} {user?.first_name}</div>
                {
                    !isNaN(allBonus)
                    && <div className="t-input-title t-descr t-descr_md">Всего бонусов: {allBonus}</div>
                }
                {
                    !isNaN(allowedBonus)
                    && <div className="t-input-title t-descr t-descr_md">Доступно к списанию: {allowedBonus}</div>
                }
                {
                    discountPercent > 0
                    && <div className="t-input-title t-descr t-descr_md">Скидка: {discountPercent}%</div>
                }
                {
                    loyaltyLevel.length > 0 && loyaltyLevel !== 'null'
                    && <div className="t-input-title t-descr t-descr_md">Ваш уровень: {loyaltyLevel}</div>
                }

                <br/>
                <div className="asgk-bonus">
                    {
                        needVerify &&
                        typeSendCode &&
                        <Verify typeSendCode={typeSendCode}
                                setCode={setCode}
                        />
                    }
                    {waitSeconds > 0 && bonusWillSpend === 0 &&
                        <div className='t-input-title t-descr t-descr_md'>Повторно запросить код можно
                            через {waitSeconds} секунд</div>
                    }
                    {
                        waitSeconds === 0 && needVerify && typeSendCode &&
                        <button type="button"
                                className="t-submit"
                                style={{color: "#F5F5F5", backgroundColor: "#957E72", borderRadius: 6}}
                                onClick={() => {
                                    beBonusWillNeedSpendAndCheckVerify()
                                }}
                        >
                            Получить код повторно
                        </button>
                    }
                    {showBonusField && allowedBonus > 0 && waitSeconds === 0 &&
                        <div className="t-input-group t-input-group_ph">
                            <label className="t-input-title t-descr t-descr_md">Списать бонусы</label>
                            <div className="t-input-block">
                                <input className="t-input"
                                       value={bonusWillNeedSpend}
                                       style={{color: '#000000', border: '1px solid #000000'}}
                                       onChange={(e) => {
                                           // @ts-ignore
                                           validateBonus(e.target.value)
                                       }}
                                />
                            </div>
                            <br/>
                            <div className="t-input-block">
                                <button type="button"
                                        className="t-submit"
                                        style={{color: "#F5F5F5", backgroundColor: "#957E72", borderRadius: 6}}
                                        onClick={() => {
                                            saveBonusValue()
                                        }}
                                >
                                    Списать бонусы
                                </button>
                            </div>
                        </div>
                    }
                </div>
                <div className="t706__cartwin-bottom t706__cartwin-totalamount-wrap t-descr t-descr_xl"
                     style={{display: 'block'}}>
                    <div className="t706__cartwin-totalamount-info"
                         style={{marginTop: 10, fontSize: 14, fontWeight: 400, opacity: 1}}>
                        <span className="t706__cartwin-totalamount-info_label">Скидка:</span>
                        <span className="t706__cartwin-totalamount-info_value">
                    <div className="t706__cartwin-prodamount-price">{discount}</div>
                    <div className="t706__cartwin-prodamount-currency"> р.</div>
                </span>

                        {bonusWillSpend > 0 && <div>
                            <span className="t706__cartwin-totalamount-info_label">Бонусами:</span>
                            <span className="t706__cartwin-totalamount-info_value">
                    <div className="t706__cartwin-prodamount-price">{bonusWillSpend}</div>
                    <div className="t706__cartwin-prodamount-currency"> р.</div>
                </span>
                        </div>}

                    </div>
                    <span className="t706__cartwin-totalamount-label">Итоговая сумма: </span>
                    <span className="t706__cartwin-totalamount">
                <div className="t706__cartwin-prodamount-price">{finishPrice}</div>
                <div className="t706__cartwin-prodamount-currency"> р.</div>
            </span>
                </div>
                {errorText && <ErrorBlock errorText={errorText}/>}
                {successText && <SuccessBlock successText={successText}/>}
            </div> : ''
        }
    </>
}
